import React from "react";
import "./Contact.css";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import twitter from "../../../images/t_icon.svg";
import github from "../../../images/git_icon.svg";
import intsagram from "../../../images/in_icon.svg";
import linkedin from "../../../images/link_icon.svg";
import artstation from "../../../images/artstation.svg";

const Contact = () => {
  return (
    <div className="contact_container">
      <div className="contact_leftside">
        <div className="contact_details_section">
          <div className="contact_card c_one">
            <div className="contact_text_section">
              <div className="email_details contact_text">
                <h3>
                  Get in <span>Touch</span>
                </h3>
                <p className="contact_subtext">
                  I'm always open to new opportunities, collaborations, and
                  interesting conversations. Whether you have a project you'd
                  like to discuss or just want to say hello, feel free to reach
                  out!
                </p>
              </div>
            </div>
          </div>
          <div className="contact_card c_two">
            <div className="contact_text_section">
              <div className="location_details contact_text">
                <h3>Direct Contact Information</h3>
                <p>
                  Prefer to contact me directly? Here's how you can reach me:
                </p>
              </div>
              <p className="contact_subtext">
                <LocationOnIcon id="contact_icon" />
                Västra Varvsgatan, Malmö, Sweden
              </p>
              <p className="contact_subtext">
                <ChatBubbleOutlinedIcon id="contact_icon" />
                <a> rickygouda9827.sweden@gmail.com</a>
              </p>
              <p className="contact_subtext">
                <CallIcon id="contact_icon" />
                Phone: +46-762882862
              </p>
            </div>
          </div>
        </div>
        <div className="s_media_icons">
          <button className="icon_buttons">
            <a
              href="https://www.artstation.com/goudasahashransu"
              target="_blank"
              rel="noreferrer"
            >
              <img src={artstation} alt="twitter" className="contact_icons" />
            </a>
          </button>
          <button className="icon_buttons">
            <a
              href="https://www.linkedin.com/in/sahashransu-gouda-a72488170/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" className="contact_icons" />
            </a>
          </button>
          <button className="icon_buttons">
            <a
              href=" https://www.instagram.com/thenoob_designer/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={intsagram} alt="instagram" className="contact_icons" />
            </a>
          </button>
          <button className="icon_buttons">
            <a
              href=" https://github.com/Sahashransu"
              target="_blank"
              rel="noreferrer"
            >
              <img src={github} atl="github" className="contact_icons" />
            </a>
          </button>
        </div>
      </div>
      <div className="contact_rightside">
        <div className="contact_heading">
          <h3>
            Got ideas? I've got <br /> the skills.
            <span className="coloured_text">Let's team up.</span>
          </h3>
          <p className="contact_subtext">
            Tell me more about yourself and what you have got in your mind.
          </p>
        </div>
        <form
          className="contact_form"
          action="mailto:rickygouda9827.sweden@gmail.com"
        >
          <div className="user_name">
            <input
              type="text"
              autoComplete="off"
              className="contact_input_filed user_name_in"
              placeholder="Your name"
            />
          </div>
          <div className="user_mail">
            <input
              type="email"
              autoComplete="off"
              className="contact_input_filed user_mail_in"
              placeholder="Your email"
            />
          </div>
          <div className="user_description">
            <textarea
              type="text"
              autoComplete="off"
              className="contact_input_filed user_description_in"
              placeholder="Tell me a bit more about yourself"
            />
          </div>
        </form>
        <div className="button_container">
          <button className="submit_button_new">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
