import Navbar from "../../../navbar/Navbar";
import Caraousal from "../../project_details/caraousal/Caraousal";
import "./DevFive.css";
import React from "react";
import { useLocation } from "react-router-dom";
import devFive from "../../../../images/dev_five/dev_five_image.jpg";

const DevFive = () => {
  const location = useLocation();
  const imgList = [devFive];
  return (
    <div className="dev_five_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{location.state.object.title}</h1>
        {/* <Caraousal props={imgList} /> */}
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/w2O6RyGVkYI`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          ></iframe>
        </div>
        <div>
          <h3>Role : {location.state.object.role}</h3>
          <h3>Team Members : {location.state.object.team_members}</h3>
        </div>
        <div className="dev_five_c_one">
          <h3>
            Master Thesis: Gamification for Community Involvement. An
            explorative research; a case study of Gotland.
          </h3>
          <p>
            This study investigates the feasibility of enhancing community
            engagement through gamification, using a prototype third-person 3D
            adventure game called Harmoni. The research targets international
            students in Gotland, Sweden, aiming to reduce cultural barriers and
            promote community involvement. By integrating game elements such as
            altruistic missions, language learning, and historical exploration,
            the prototype seeks to motivate real-life participation in community
            activities. Data collected from pre- and post-surveys and follow-up
            interviews with a small sample size (n=10) suggest that gamification
            can positively impact community involvement. Despite the limitations
            of sample size, the findings indicate that thoughtfully designed
            gamified experiences can foster a sense of belonging and encourage
            active participation in community life. The study underscores the
            potential of gamification in educational and social contexts,
            offering insights for future applications and improvements in
            game-based community engagement strategies.
          </p>
          <p>
            The homepage has the master's thesis attached. Section 3.1 Harmoni's
            Design discusses design choices made for the prototype's
            construction, and Appendix G details the development procedures.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DevFive;
